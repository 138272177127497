jQuery(document).ready(function($) {
  require('./components/mobile-header.js');
  require('./components/accordion.js');
  require('./components/video.js');
  require('./components/slider.js');


  jQuery('.team .popup-button').click(function() {
    jQuery(this).closest('.single-member').addClass('active');
    jQuery('body, html').css('overflow-y', 'hidden');

    jQuery('.popup-close').click(function() {
      jQuery(this).closest('.single-member').removeClass('active');
      jQuery('body, html').css('overflow-y', 'auto');
    })
  });
});


// Calculate Header Height
var box = document.querySelector('.header');
var height = box.offsetHeight;
document.querySelector('.header-margin').style.marginTop = height + 'px';

window.addEventListener('resize', resizeFunction);
function resizeFunction() {
  var box2 = document.querySelector('.header');
  var height2 = box2.offsetHeight;
  document.querySelector('.header-margin').style.marginTop = height2 + 'px';
}